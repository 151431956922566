<template>
  <main class="main cart-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="desc empty-desc" v-if="!$parent.cartContents.length">
            Cart is empty!
          </div>
          <div class="left" v-if="$parent.cartContents.length">
          <div class="title">{{$t("Cart")}}</div>
            <div class="product-list">
              <div class="item" v-for="(item, i) in $parent.cartContents" :key="i">
                <div class="product">
                  <div class="preview">
                    <img :src="imgDomain + item.item.img_url" class="img"/>
                  </div>
                  <div class="title">{{item.item.title}}</div>
                </div>
                <div class="count">
                  <div class="count__item minus title" @click="$parent.removeOneFromCart(item, item.item_id)">-</div>
                  <input type="number" value="1" v-model="item.count"/>
                  <div class="count__item plus title" @click="$parent.addToCart(item.item, item.item_id)">+</div>
                </div>
                <div class="price">{{item.item.price}} <span class="currency">{{ currencySymbol }}</span></div>
                <div class="delete" @click="$parent.removeFromCart(item.item, item.item_id)">
                  <img src="./../images/remove.svg" class="img"/>
                </div>
              </div>
            </div>
          </div>
          <div class="right" v-if="$parent.cartContents.length">
            <div class="wrapper">
              <div class="title small">{{$t("Contact information")}}</div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("First name")}}:</div>
                <input type="text" :placeholder="$t('First name')" v-model="name"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("Last name")}}:</div>
                <input type="text" :placeholder="$t('Last name')" v-model="surname"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("Phone")}}:</div>
                <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso" :default-country-code="convertedShortName" @update="phoneDataUpdate($event)" />
              </div>
              <div class="input-container steam-input-container">
                <div class="desc"><span class="red">*</span>{{$t("Steam ID")}}:</div>
                <input type="text" :placeholder="$t('Steam ID')" v-model="steamId"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("Country")}}:</div>
                <select v-model="country">
                  <option disabled selected value="">Country</option>
                  <option v-for="(item, i) in countryOptions" :value="item" :key="i">{{ item.title }}</option>
                </select>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("City")}}:</div>
                <input type="text" :placeholder="$t('City')" v-model="city"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("Address")}}:</div>
                <input type="text" :placeholder="$t('Address')" v-model="address"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{$t("Post Code")}}:</div>
                <input type="text" :placeholder="$t('Post Code')" v-model="postCode"/>
              </div>
              <div class="input-container">
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms"/>
                      <div class="checkbox"></div>
                      <span class="title">{{$t("I agree with")}} </span>
                      <a @click="$parent.goToPage('privacy')" class="title">{{$t("Privacy Policy")}}</a>
                      <span class="title"> {{$t("and")}} </span>
                      <a @click="$parent.goToPage('terms')" class="title">{{$t("Terms and Conditions")}}</a>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="wrapper">
              <div class="row">
                <div class="desc">{{$t("Total")}}:</div>
                <div class="title">{{$parent.totalSum}} <span class="currency">{{ currencySymbol }}</span></div>
              </div>
              <button @click="submit" :class="['button', {'disabled': !requiredFieldsAreFilled}]">
                <span>{{$t("Confirm")}}</span>
              </button>
              <transition name="fade">
                <div class="desc red" v-if="$parent.error">{{$parent.error}}</div>
              </transition>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
import {mapGetters} from "vuex";

export default {
  name: 'Cart',
  props: [],
  components: {
    VuePhoneNumberInput
  },
  data: function() {
    return {
      imgDomain: '',
      countryOptions: [],
      countryOptionsId: [],
      name: '',
      surname: '',
      phone: '',
      steamId: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      phoneFull: "",
      convertedShortName: '',
      countryCode: '',
      countryCodeList: [],
      profilePhone: '',
      countryOptionsIso: []
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProfile();
    this.getCountries();
    this.$parent.getCartContents();
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.steamId && this.country && 
        this.city && this.address  
        && this.postCode && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
    ...mapGetters('app', ['currencySymbol'])
  },
  methods: {
    getProfile() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        if (res.status == 200) {
          self.email = res.data.email;
          self.name = res.data.name;
          self.surname = res.data.surname;
          self.phone = res.data.phone.replace(/^[^\s]*\s/, '');
          self.profilePhone = res.data.phone;
          self.getAllCountryCodes();
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    getAllCountryCodes() {
      this.$http.get(process.env.VUE_APP_API + 'phone-codes')
      .then((res) => {
        if (res.data.status == "OK") {
          this.countryCodeList = res.data.payload; 
          this.countryCode = this.getSymbolsBeforeSpace(this.profilePhone).replace( '+' ,'');
          this.getShortNameByPhoneCode(this.countryCode, this.countryCodeList);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    getShortNameByPhoneCode(phoneCode, codeList) {
      for (let i = 0; i < codeList.length; i++) {
        const countryCode = Object.keys(codeList[i])[0];
        const codeValue = codeList[i][countryCode].code
        if (codeValue == phoneCode) {
          this.convertedShortName = countryCode;
        }
      }
    },
    getSymbolsBeforeSpace(inputString) {
      const regex = /^[^\s]+/;
      const match = inputString.match(regex);

      if (match) {
          return match[0];
      } else {
          return ""; // Return an empty string if no symbols are found
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
      console.log(this.countryId)
    },
    submit() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phoneFull,
        "steamId": this.steamId,
        "country": this.country.id,
        "city": this.city,
        "address": this.address,
        "postCode": this.postCode
      }
      this.$emit('orderSubmit', data);
    },
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
  }
}
</script>