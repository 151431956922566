<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="new-slider">
          <SlickItem v-if="$parent.newArrivals.length" :list="$parent.newArrivals" :tag="''" :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCart" @goToProduct="goToProduct" :currency="currency" />
        </div>
        <div class="wrapper home">
          <div class="left">
            <div class="title big">
              {{ $t("Buy the best CS2 skins for the best prices!") }}
            </div>
            <router-link to="/products" class="button" v-text="$t('BUY SKINS')" />
          </div>
        </div>
      </div>
      <div class="section categories-section" v-if="categoryOptions && categoryOptions.length">
        <div class="wrapper">
          <div class="categories__item" v-for="item in categoryOptions" :key="item">
            <img :src="getImageSource(item)" class="img img-mob" />
            <img :src="getImageSource(item + '_bg')" class="img bg" />
            <img :src="getImageSource(item + '_hover')" class="img hover" />
            <img :src="getImageSource(item + '2')" :class="'img img2 img2_'+item.toLowerCase().replace(/ /g, '_')" />
            <img :src="getImageSource(item + '1')" :class="'img img1 img1_'+item.toLowerCase().replace(/ /g, '_')" />
            <router-link :to="'/products/' + item.toLowerCase().replace(/ /g, '_')" class="button small" v-text="$t(item)"/>
          </div>
        </div>
      </div>
      <div class="section step-section">
        <div class="wrapper">
          <div class="title big" v-text="$t('Buy Skins Easily')"/>
          <div class="list">
            <div class="item">
              <img class="icon" src="./../images/steps/icon1.svg" />
              <div class="title" v-text="$t('Log In')" />
              <img class="img" src="./../images/steps/step1.png" />
            </div>
            <div class="item">
              <img class="icon" src="./../images/steps/icon2.svg" />
              <div class="title" v-text="$t('Select')" />
              <img class="img" src="./../images/steps/step2.png" />
            </div>
            <div class="item">
              <img class="icon" src="./../images/steps/icon3.svg" />
              <div class="title" v-text="$t('Buy')" />
              <img class="img" src="./../images/steps/step3.png" />
            </div>
          </div>
          <router-link to="/products" class="button" v-text="$t('BUY SKINS')" />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from "../components/SlickItem.vue";

export default {
  name: "Home",
  props: ["addToCartChosenItem", "currency"],
  components: {
    SlickItem
  },
  data: function () {
    return {
      imgDomain: "",
      categoryOptions: []
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getCategoryOptions();
  },
  methods: {
    getImageSource(item) {
      return require(`./../images/categories/${(item.toLowerCase().replace(/ /g, "_"))}.png`);
    },
    getCategoryOptions() {
      this.$http.get(process.env.VUE_APP_API + "items/filter-data")
      .then((res) => {
        this.categoryOptions = res.data.payload.types;
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      });
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
    addToCart(item, id) {
      this.$emit("addToCart", item, id);
    },
  },
};
</script>
